<template>
  <div class="semifished-ingredient-table">
    <add-ingredient :show="showAddIngredient" @add-ingredient="saveFn" />
    <el-table ref="dish-ingredient-table" v-loading="loading" :data="data" style="width: 100%">
      <el-table-column prop="ingredient.title" :width="200" label="Название" />
      <el-table-column label="Методы">
        <template slot-scope="scope">
          <column-methods :methods="scope.row" @selectMethod="changeIngredientSize" />
        </template>
      </el-table-column>
      <el-table-column prop="count" :width="180" label="Брутто">
        <template slot-scope="scope">
          <column-brutto
            :ingredient-id="scope.row.ingredient.id"
            :brutto.sync="scope.row.brutto"
            :unit="scope.row.ingredient.unit"
            editable
            @changeBrutto="changeIngredientNetto"
          />
        </template>
      </el-table-column>
      <el-table-column prop="count" :width="180" label="Нетто">
        <template slot-scope="scope">
          <column-netto
            :ingredient-id="scope.row.ingredient.id"
            :netto.sync="scope.row.netto"
            :count.sync="scope.row.count"
            :unit="scope.row.ingredient.unit"
            @changeNetto="changeIngredientNetto"
          />
        </template>
      </el-table-column>
      <el-table-column prop="rules" :width="70">
        <template slot-scope="scope">
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <energy-value
      v-if="semis"
      :calories="semis.calories"
      :proteins="semis.proteins"
      :fats="semis.fats"
      :carbohydrates="semis.carbohydrates"
      :netto="semis.netto"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  STORE_SEMIFINISHED_INGREDIENT,
  UPDATE_SEMIFINISHED_INGREDIENT,
  DELETE_SEMIFINISHED_INGREDIENT,
} from '@/app/administration/semifisheds/shared/state/types/action_types';
import { notifyMixin } from '@/app/settings/dishes/shared/mixins/notify-mixin';
import ColumnMethods from './table-columns/column-methods.vue';
import ColumnBrutto from './table-columns/column-brutto.vue';
import ColumnNetto from './table-columns/column-netto.vue';
import EnergyValue from './energy-value.vue';
import AddIngredient from './add-ingredient.vue';

export default {
  name: 'SemifishedIngredientTable',
  components: { ColumnMethods, ColumnBrutto, ColumnNetto, EnergyValue, AddIngredient },
  mixins: [notifyMixin],
  props: {
    data: {
      type: Array,
      required: false,
      default: null,
    },
    showAddIngredient: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      semis: (state) => state.semifishedsState.semisData.selected,
      loading: (state) => state.semifishedsState.semisData.loading,
    }),
  },
  methods: {
    ...mapActions({
      delete: DELETE_SEMIFINISHED_INGREDIENT,
      update: UPDATE_SEMIFINISHED_INGREDIENT,
      store: STORE_SEMIFINISHED_INGREDIENT,
    }),
    handleDelete(row) {
      this.$confirm('Вы точно хотите удалить?', 'Предупреждение', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Закрыть',
        type: 'warning',
      })
        .then(async () => {
          await this.delete(row.ingredient.id);
          if (this.error) {
            this.showErrorMessage('Ошибка удаления');
          }
          this.showSuccessMessage('Ингредиент успешно удален');
        })
        .catch(() => {});
    },
    async changeIngredientSize(method) {
      await this.update(method);
    },
    async saveFn(dataIngredient) {
      await this.store(dataIngredient);
      this.showSuccessMessage('Успешно добавлен');
    },
    async changeIngredientNetto(dataNetto) {
      await this.update(dataNetto);
    },
  },
};
</script>
