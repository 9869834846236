<template>
  <div class="semifished">
    <div v-loading="loading">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-row :gutter="20">
            <el-col
              v-for="(input, index) in fieldsInputs"
              :key="index"
              :span="input.span"
              class="mb-5"
            >
              <semifished-item-input
                :field-name="input.name"
                :field-label="input.lable"
                :field-value.sync="dish[input.name]"
                :visible-value="input.visibleValue ? dish[input.visibleValue] : null"
                :type="input.type"
                :search-fn="input.searchFn"
                @change-field="changeField"
              />
            </el-col>
            <el-col :span="24">
              <div class="is-flex is-justify-content-flex-end mb-6">
                <el-button type="success"> Cохранить полуфабрикат </el-button>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div>
      <div class="is-flex is-justify-content-space-between is-align-items-center mb-4">
        <h3 class="title is-4">Ингредиенты</h3>
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="showAddIngredient = !showAddIngredient"
        >
          Добавить ингредиент
        </el-button>
      </div>
      <semifished-ingredient-table
        v-if="dish.ingredients"
        :show-add-ingredient="showAddIngredient"
        :data="dish.ingredients"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SELECTED, LOADING } from '../shared/state/types/getter_types';
import { FETCH_SEMIFINISHED, UPDATE_SEMIFINISHED } from '../shared/state/types/action_types';
import SemifishedItemInput from './semifished-item-prop/semifished-item-input.vue';
import SemifishedIngredientTable from './semifished-ingredient-table/semifished-ingredient-table.vue';

export default {
  name: 'Dish',
  components: { SemifishedItemInput, SemifishedIngredientTable },
  data() {
    return {
      fieldsInputs: [
        { name: 'title', lable: 'Название', span: 12, type: 'input' },
        { name: 'barcode', lable: 'Штрихкод', span: 12, type: 'input' },
        { name: 'workshop', lable: 'Цех', span: 12, type: 'input' },
        { name: 'tax', lable: 'Налог', span: 12, type: 'input' },
        { name: 'cooking_time', lable: 'Время приготовления', span: 12, type: 'time' },
        { name: 'cooking_process', lable: 'Процесс приготовления', span: 12, type: 'textarea' },
        { name: 'losses_cleaning', lable: '% потерь при отчистке', span: 12, type: 'input' },
        { name: 'losses_frying', lable: '% потерь при жарке', span: 12, type: 'input' },
        { name: 'losses_cooking', lable: '% потерь при варке', span: 12, type: 'input' },
        { name: 'losses_baking', lable: '% потерь при запекании', span: 12, type: 'input' },
        { name: 'losses_stew', lable: '% потерь при тушении', span: 12, type: 'input' },
      ],
      showAddIngredient: false,
    };
  },
  computed: {
    ...mapGetters({
      dish: SELECTED,
      loading: LOADING,
    }),
  },
  async mounted() {
    await this.select(this.$route.params.id);
  },
  methods: {
    ...mapActions({
      select: FETCH_SEMIFINISHED,
      update: UPDATE_SEMIFINISHED,
    }),
    async changeField() {
      await this.update(this.dish);
    },
    async sendDataIngredients() {
      this.loading = true;
      try {
        await this.$store.dispatch('STORE_DISH_INGREDIENT', { ingredient_id: this.ingredientId });
        this.$notify({
          title: 'Успешно',
          message: 'Ингредиент успешно добавлен',
          duration: 1500,
          type: 'success',
        });
      } catch (error) {
        this.$notify.error({
          title: 'Ошибка',
          message: 'Ошибка',
          duration: 1500,
        });
      }
      await this.handleClose();
      this.loading = false;
      return true;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
.image {
  width: 350px;
  height: 350px;
  object-fit: contain;
}
.avatar-uploader {
  height: 300px;
  .el-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 100px;
  }
}
</style>
